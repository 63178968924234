.sns {
	text-align: left;
	margin-left: 5px;
	li {
		&:first-child { margin-left: 0px; }
		&:last-child { margin-right: 2px; }
		display: inline-block;
		line-height: 0;
		vertical-align: top;
		margin: 0 1px;
	}
	iframe {
		margin: 0 !important;
	}
}

@media screen and (max-width: 780px){
    .sns > li.twitter {
        width: 60px !important;
        margin: 0 15px 10px 0;
    }
    .sns > li.hatena {
        width: 115px !important;
        margin: 0 15px 10px 0;
    }
    .sns > li.pocket {
        width: 80px !important;
        margin: 0 15px 10px 0;
    }
}
